import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicroscope, faRobot, faCube, faTools, faTint, faThermometerHalf, faEye } from '@fortawesome/free-solid-svg-icons';

const equipmentData = [
  {
    manufacturer: 'ESE',
    items: [
      {
        icon: faMicroscope, // Adjust as needed
        title: 'US-7000X',
        subtitle: 'Solder Paste Printer',
        description: 'US-7000C Solder Paste Printer with 2D Inspection',
        quantity: 3,
      },
    ],
  },
  {
    manufacturer: 'JUKI',
    items: [
      {
        icon: faRobot,
        title: 'FX-3',
        subtitle: 'SMT Placement Machine',
        description: 'SMT Placement Machine',
        quantity: 1,
      },
      {
        icon: faRobot,
        title: 'KE-2080RL',
        subtitle: 'SMT Placement Machine',
        description: 'SMT Placement Machine',
        quantity: 2,
      },
      {
        icon: faRobot,
        title: 'KE-2070EL',
        subtitle: 'SMT Placement Machine',
        description: 'SMT Placement Machine',
        quantity: 2,
      },
      {
        icon: faRobot,
        title: 'KE-2080EL',
        subtitle: 'SMT Placement Machine',
        description: 'SMT Placement Machine',
        quantity: 2,
      },
    ],
  },
  {
    manufacturer: 'HELLER',
    items: [
      {
        icon: faThermometerHalf,
        title: '1809EXL',
        subtitle: 'Reflow Oven',
        description: 'SMT Reflow Oven',
        quantity: 1,
      },
      {
        icon: faThermometerHalf,
        title: 'Mark III',
        subtitle: 'Reflow Oven',
        description: 'SMT Reflow Oven',
        quantity: 2,
      },
    ],
  },
  {
    manufacturer: 'MIRTEC',
    items: [
      {
        icon: faEye,
        title: 'MV-7L',
        subtitle: 'AOI Machine',
        description: 'AOI (automatic optical inspection)',
        quantity: 1,
      },
      {
        icon: faEye,
        title: 'MV-7xi',
        subtitle: 'Advanced AOI Machine',
        description: 'Advanced AOI machine with 3D inspection capability',
        quantity: 2,  // Set the appropriate quantity
      },
    ],
  },  
  {
    manufacturer: 'SyberOptic',
    items: [
      {
        icon: faEye,
        title: 'Q3000',
        subtitle: 'AOI Machine',
        description: 'AOI (3-D automatic optical inspection)',
        quantity: 1,
      },
      {
        icon: faEye,
        title: 'SE 500 Ultra',
        subtitle: 'SPI Unit',
        description: 'SPI unit',
        quantity: 1,
      },
    ],
  },
  {
    manufacturer: 'PVA',
    items: [
      {
        icon: faTint,
        title: 'B00-1156',
        subtitle: 'UV Oven',
        description: 'UV Oven (cures conformal coating)',
        quantity: 1,
      },
      {
        icon: faTint,
        title: 'UV2000-20-1',
        subtitle: 'UV Oven',
        description: 'UV Oven (cures conformal coating)',
        quantity: 1,
      },
      {
        icon: faTint,
        title: 'Delta 8',
        subtitle: 'Conformal Coating Machine',
        description: 'Conformal Coating Machine',
        quantity: 1,
      },
    ],
  },
  {
    manufacturer: 'RPS',
    items: [
      {
        icon: faTools,
        title: 'CH-2555',
        subtitle: 'Selective Soldering Machines',
        description: 'Selective Soldering Machines',
        quantity: 2,
      },
    ],
  },
  {
    manufacturer: 'ON SITE GAS SYSTEMS',
    items: [
      {
        icon: faTools,
        title: 'N25-T',
        subtitle: 'Nitrogen Generator',
        description: 'Nitrogen Generator',
        quantity: 2,
      },
    ],
  },
  {
    manufacturer: 'Accue-EYE',
    items: [
      {
        icon: faEye,
        title: 'M1000',
        subtitle: 'AOI for PTH',
        description: 'AOI for PTH',
        quantity: 1,
      },
    ],
  },
  {
    manufacturer: 'Electrovert',
    items: [
      {
        icon: faTools,
        title: 'Vectra Elite',
        subtitle: 'Solderwave Machine',
        description: 'Solderwave Machine (LEAD AND LEAD-FREE CAPABLE)',
        quantity: 1,
      },
      {
        icon: faTools,
        title: 'Aquastorm 200',
        subtitle: 'In-Line Cleaner',
        description: 'In-Line Cleaner',
        quantity: 1,
      },
    ],
  },
  {
    manufacturer: 'VCD-Sequencer-5',
    items: [
      {
        icon: faCube,
        title: '6241D',
        subtitle: 'Axial Insertion & Sequencer Machine',
        description: 'Axial Insertion & Sequencer Machine',
        quantity: 1,
      },
    ],
  },
];

const Equipment = () => {
  const [equipment, setEquipment] = useState(equipmentData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container equipment-list">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="page-title">Our Equipment</h2>
        </div>
      </div>
      {equipment.map((group, index) => (
        <div className="manufacturer-group" data-aos="fade-up" key={index}>
          <h4 className="manufacturer-title">{group.manufacturer}</h4>
          <div className="equipment-grid">
            {group.items.map((item, idx) => (
              <div className="equipment-card" key={idx}>
                <div className="card-body">
                  <FontAwesomeIcon icon={item.icon} className="equipment-icon" />
                  <h5 className="card-title">{item.title}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">{item.subtitle}</h6>
                  <p className="card-text">{item.description}</p>
                  <p className="card-quantity">Quantity: {item.quantity}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Equipment;
