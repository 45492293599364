import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faTools, faUsers } from '@fortawesome/free-solid-svg-icons';

const Features = () => {
  return (
    <div className="featuresbg">
      <h2 className="section-title">Let's Get Started</h2>
      <div className="features-row">
        <div className="feature" data-aos="fade-up">
          <FontAwesomeIcon icon={faCogs} size="3x" className="feature-icon" />
          <h4>Prototype to Production</h4>
          <p>Let's build amazing electronics together. From prototype to production, companies small and large around the world entrust us to be their electronic manufacturing partner.</p>
        </div>
        <div className="feature" data-aos="fade-up" data-aos-delay="100">
          <FontAwesomeIcon icon={faTools} size="3x" className="feature-icon" />
          <h4>State-of-the-art Equipment</h4>
          <p>We continually invest to better serve our customers. We have the capabilities to assemble components down to 01005 passives, QFNs, BGAs, and µBGAs.</p>
        </div>
        <div className="feature" data-aos="fade-up" data-aos-delay="200">
          <FontAwesomeIcon icon={faUsers} size="3x" className="feature-icon" />
          <h4>Certified Experts</h4>
          <p>Our highly experienced staff members are IPC-A-610G and IPC J-STD-001G (with the Space Addendum) certified. Our full-time IPC Trainer provides continual education and training.</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
