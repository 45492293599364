import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  return (
    <div className="about-us-section" id="quoteme" data-aos="fade-up">
      <div className="conxtainer">
        <h3>About Us</h3>
        <p>
          E-Mek combines processing ability with a Midwestern work ethic, and a genuine sense of urgency.
          We understand the importance of time to market, and control the supply chain from prototype,
          through production, to end of life. We maximize sales opportunities for our customers by
          reducing lead times, as well as providing repair services for out of warranty product.
          Come visit us in our 50,000 square foot facility in Dayton, Ohio at the crossroads of I-70 and I-75.
        </p>
        <div className="about-us-details">
          <ul>
            <li>
              <h5>Mission</h5>
              <p>Our mission is to deliver high-quality electronic manufacturing services that exceed customer expectations.</p>
            </li>
            <li>
              <h5>Vision</h5>
              <p>To be the leading provider of innovative electronic manufacturing solutions, renowned for our commitment to excellence and customer satisfaction.</p>
            </li>
            <li>
              <h5>Values</h5>
              <p>Integrity, quality, and customer focus are at the core of everything we do.</p>
            </li>
            <li>
              <h5>Services</h5>
              <p>We offer a comprehensive range of services from prototype development to full-scale production, including repair services for out-of-warranty products.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const RequestConsultation = () => {
  const [formData, setFormData] = useState({
    Full_Name: '',
    Company: '',
    Phone_Number: '',
    Email: '',
    Subject: '',
    Comments: '',
    custom_antispam_field: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState(''); // State for tracking form status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(formData.Phone_Number)) {
      alert('Please enter a valid 10-digit phone number.');
      return;
    }
    try {
      const response = await axios.post('/api/send', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSubmissionStatus('FORM SUCCESSFULLY SUBMITTED. YOU WILL HEAR FROM US SOON. THANK YOU.');
    } catch (error) {
      console.log("Error response:", error.response); // Log detailed error information
      setSubmissionStatus('Error sending message, please try again.');
    }
  };
  
  

  return (
    <div className="request-consultation" id="contactus" data-aos="fade-up">
      <div className="container">
        <h3>Request a Free Consultation</h3>
        <form id="contact-form" onSubmit={handleSubmit}>
          {/* Your form fields go here */}
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input 
                  type="text" 
                  className="form-control modern-input" 
                  name="Full_Name" 
                  placeholder="Full Name*" 
                  required 
                  onChange={handleChange} 
                  minLength="2"
                  maxLength="50"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input 
                  type="text" 
                  className="form-control modern-input" 
                  name="Company" 
                  placeholder="Company*" 
                  required 
                  onChange={handleChange} 
                  minLength="2"
                  maxLength="50"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input 
                  type="tel" 
                  className="form-control modern-input" 
                  name="Phone_Number" 
                  placeholder="Phone*" 
                  required 
                  onChange={handleChange}
                  pattern="[0-9]{10}"
                  title="Please enter a valid 10-digit phone number"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input 
                  type="email" 
                  className="form-control modern-input" 
                  name="Email" 
                  placeholder="Email*" 
                  required 
                  onChange={handleChange}
                  maxLength="50"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <select 
              className="form-control modern-input" 
              name="Subject" 
              required 
              onChange={handleChange}
            >
              <option value="" disabled selected>I want to discuss*</option>
              <option value="PCB Assembly (Mass Volumes)">PCB Assembly (Mass Volumes)</option>
              <option value="PCB Assembly (Low to Medium Volumes)">PCB Assembly (Low to Medium Volumes)</option>
              <option value="PCB Assembly (Prototyping)">PCB Assembly (Prototyping)</option>
              <option value="Electromechanical / Box Build">Electromechanical / Box Build</option>
              <option value="Cable / Wire Harness Assembly">Cable / Wire Harness Assembly</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <textarea 
              className="form-control modern-input" 
              name="Comments" 
              rows="6" 
              placeholder="Comments" 
              onChange={handleChange}
              maxLength="500"
            ></textarea>
          </div>
          <div className="form-group">
            <label>Using only numbers, how many days are in one week?</label>
            <input 
              type="text" 
              className="form-control modern-input" 
              name="custom_antispam_field" 
              required 
              onChange={handleChange}
              pattern="[7]{1}"
              title="Please enter the correct number of days in a week"
            />
          </div>
          <button type="submit" className="btn btn-danger custom-btn">Submit Now</button>
        </form>

        {/* Display success or error message */}
        {submissionStatus && <div className="alert alert-info mt-4">{submissionStatus}</div>}
      </div>
    </div>
  );
};

export default RequestConsultation;