import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Carousel = ({ title, subtitle, videoSrc, buttonText, buttonLink }) => {
  return (
    <div id="carousel" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active" style={{ backgroundImage: "url('pcb.png')", height: '700px', backgroundSize: 'cover', backgroundPosition: 'center' }}>
          {/* You can add a video background if needed */}
          {videoSrc && (
            <video autoPlay loop muted style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', zIndex: '-1' }}>
              <source src={videoSrc} type="video/mp4" />
            </video>
          )}
          <div className="carousel-caption d-flex flex-column justify-content-center align-items-center" data-aos="fade-up">
            <h1 className="carousel-title">{title}</h1>
            <p className="carousel-subtitle">{subtitle}</p>
            <a href={buttonLink} className="btn btn-primary">{buttonText}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
