import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Header = () => {
  return (
    <div>
      <div className="header-top" data-aos="fade-down">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <i className="fas fa-info-circle" style={{ marginRight: '10px' }}></i>
              <p className="m-0">A leading provider of PCB Assembly services in the USA.</p>
            </div>
          </div>
        </div>
      </div>

      <header data-aos="fade-down">
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div className="container">
            <a className="navbar-brand" href="/"><img src="Logo.png" alt="E-mek" style={{ height: '50px' }} /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item"><a className="nav-link nav-link-home" href="/">Home</a></li>
                <li className="nav-item"><a className="nav-link" href="/#services">Services</a></li>
                <li className="nav-item"><a className="nav-link" href="/#contactus">Quote</a></li>
              </ul>
              <div className="ml-auto">
                <a href="#contactus" className="btn btn-danger">Contact Us</a>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
