import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThermometerHalf, faMicroscope, faRobot, faTint, faEye } from '@fortawesome/free-solid-svg-icons';

const EquipmentHighlight = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="services" style={{ backgroundColor: '#f8f8f8', padding: '50px 0' }}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h3 className="section-title">New Equipment Highlight</h3>
          </div>
        </div>
        <div className="equipment-container">
          <div className="equipment-card" data-aos="fade-up">
            <FontAwesomeIcon icon={faMicroscope} className="equipment-icon" />
            <h4>Solder Paste Printer</h4>
            <p>ESE US-7000X: Advanced Solder Paste Printer with 2D Inspection for high-quality PCB production.</p>
          </div>
          <div className="equipment-card" data-aos="fade-up" data-aos-delay="100">
            <FontAwesomeIcon icon={faRobot} className="equipment-icon" />
            <h4>SMT Placement Machine</h4>
            <p>JUKI FX-3: High-speed SMT Placement Machine designed for precision and efficiency in electronic assemblies.</p>
          </div>
          <div className="equipment-card" data-aos="fade-up" data-aos-delay="200">
            <FontAwesomeIcon icon={faThermometerHalf} className="equipment-icon" />
            <h4>Syberoptic Q3000 Machine</h4>
            <p>Syberoptic Q3000: Cutting-edge machine providing advanced optical measurement solutions for precise PCB analysis.</p>
          </div>
          {/* Corrected to MV-7xi and uppercase 'XI' */}
          <div className="equipment-card" data-aos="fade-up" data-aos-delay="300">
            <FontAwesomeIcon icon={faEye} className="equipment-icon" />
            <h4>AOI Machine</h4>
            <p>Mirtec MV-7xi: Automated Optical Inspection (AOI) machine for ensuring high-quality electronic assemblies.</p>
          </div>
          <div className="equipment-card" data-aos="fade-up" data-aos-delay="400">
            <FontAwesomeIcon icon={faTint} className="equipment-icon" />
            <h4>Conformal Coating Machine</h4>
            <p>PVA Delta 8: Advanced Conformal Coating Machine providing protective coatings for electronic assemblies.</p>
          </div>
        </div>
        <div className="view-more" data-aos="fade-up">
          <Link to="/equipment" className="btn btn-view-more">View More</Link>
        </div>
      </div>
    </div>
  );
};

export default EquipmentHighlight;
