import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer>
      <div className="footer-main" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-md-3" data-aos="fade-up">
              <img src="Logo.png" alt="E-mek" className="footer-logo" />
              <p>A full service electronic PCB Assembly Company.</p>
              <div className="footer-certifications"></div>
            </div>

            <div className="col-md-2" data-aos="fade-up">
              <h6>Quick Links</h6>
              <ul className="footer-links">
                <li><a href="/">Home</a></li>
                <li><a href="/services">Services</a></li>
                <li><a href="/quote">Quote</a></li>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/careers">Careers</a></li>
              </ul>
            </div>
            <div className="col-md-5" data-aos="fade-up">
              <h6>Contact us</h6>
              <ul className="footer-contact">
                <li><FontAwesomeIcon icon={faMapMarkerAlt} /> E-Mek, 7410 Webster St, Dayton, OH 45414, USA</li>
                <li><FontAwesomeIcon icon={faPhone} /> <a href="tel:937-424-3166">(937) 424-3166</a></li>
                <li><FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:sales@emek.com">sales@e-mek.com</a></li>
                <li>Monday - Friday : 7:00am - 4:00pm</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom" data-aos="fade-up">
        <div className="container text-center">
          <p>© 2024 E-mek. All rights reserved. | <a href="vendor-tos">Vendor TOS</a> | <a href="customer-tos">Customer TOS</a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
