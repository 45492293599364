import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'animate.css/animate.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './styles/Equipment.css';
import './styles/main.css';
import Header from './components/Header';
import Carousel from './components/Carousel';
import Features from './components/Features';
import WhyUs from './components/WhyUs';
import EquipmentHighlight from './components/EquipmentHighlight';
import RequestConsultation from './components/RequestConsultation';
import Footer from './components/Footer';
import Equipment from './components/Equipment'; // Import the Equipment component

const App = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, offset: 100, easing: 'ease-in-out', once: true });
  }, []);

  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Carousel
                title="Your trusted PCB assembly partner."
                subtitle="QUALITY BUILT-IN"
                videoSrc="vid.mp4"
                buttonText="Get a PCB Assembly Quote"
                buttonLink="#contactus"
              />
              <Features />
              <WhyUs />
              <EquipmentHighlight />
              <RequestConsultation />
            </>
          } />
          <Route path="/equipment" element={
            <>
              <Carousel
                title="Our Equipment."
                subtitle="QUALITY BUILT-IN"
                videoSrc="vid.mp4"
                buttonText="Get a PCB Assembly Quote"
                buttonLink="./#contactus"
              />
            <Equipment />
            </>
          } 
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
