import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const WhyUs = () => {
  return (
    <div className="why-us" data-aos="fade-up">
      <div className="container">
        <h3 className="section-title">Why E-mek?</h3>
        <p className="section-description">When we say that we care, we actually mean it. When you partner with us, we do everything possible to make sure your assemblies are completed on-time to your specifications with the highest quality. We truly consider ourselves an extension of your company's team.</p>
        <ul className="expertise-list">
          <li className="expertise-card" data-aos="fade-up" data-aos-delay="200">
            <i className="fas fa-hospital"></i>
            <h4>Medical</h4>
            <p>We have the expertise to assemble RoHs IPC Class 3 medical devices. Rigid, Flex, and Rigid-flex PCB assemblies.</p>
          </li>
          <li className="expertise-card" data-aos="fade-up" data-aos-delay="300">
            <i className="fas fa-car"></i>
            <h4>Automotive</h4>
            <p>Whether it be power or RF boards related to self-driving cars, we have the expertise to get the job done right the first time.</p>
          </li>
          <li className="expertise-card" data-aos="fade-up" data-aos-delay="400">
            <i className="fas fa-industry"></i>
            <h4>Industrial</h4>
            <p>We can assemble critical control system printed circuit boards that will be exposed to challenging environments.</p>
          </li>
          <li className="expertise-card" data-aos="fade-up" data-aos-delay="500">
            <i className="fas fa-broadcast-tower"></i>
            <h4>Telecommunications</h4>
            <p>Radio Frequency (RF) PCB assembly has unique challenges that we can help with. Roger/FR4 hybrid PCB assemblies are routine for us.</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhyUs;
